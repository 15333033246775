import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import HeroBannerWithCTA from 'components/heroBannerWithCTA'
import Intro from 'components/intro'
import ServiceGrid from 'components/servicesGrid'
import { TimelineSplit } from 'components/timeline'
import ContactForm from 'components/contactForm'
import TestimonialCarousel from 'components/testimonialCarousel'
import PartnerCarousel from 'components/partnerCarousel'
import Features from 'components/features'
import FAQs from 'components/faqs'
import WorkWithUs from 'components/workWithUs'
import CopyBlock from 'components/copyBlock'
import CopySplitWithCTA from 'components/copySplitWithCTA'
import LatestNews from 'components/latestNews'
import { graphql } from 'gatsby'

const ITSecurityPage = ({ data, location }) => {
  const content = data.itsecurityJson
  return (
    <Layout location={location}>
      <Helmet>
        <title>{content.metaTitle}</title>
        <meta name="description" content={content.metaDescription} />
      </Helmet>
      <HeroBannerWithCTA hero={content.heroBanner} highlightColor="turquoise" />
      <Intro intro={content.intro} />
      <ServiceGrid services={content.services} withCTA />
      <TimelineSplit timeline={content.timeline} />
      <WorkWithUs data={content.whyUsSection} topMargin />
      <Features features={data.homeJson.features} withHeading={true} heading={content.features.heading} bgTint />

      <CopyBlock topMargin>
        <TestimonialCarousel heading="What Our Clients Say" />
      </CopyBlock>
      <PartnerCarousel bgTint borderTop={false} />
      <CopyBlock topMargin id="contact">
        <ContactForm
          heading="Want to work with us?"
          intro={
            <>
              Fill out the form below to request a call back. Or give us a call on{' '}
              <a href="tel:00442070525011">0207 0525 011</a> or drop us an email{' '}
              <a href="mailto:info@pcwsolutions.co.uk">info@pcwsolutions.co.uk</a>
            </>
          }
        />
      </CopyBlock>
      <CopyBlock bgGrad>
        <CopySplitWithCTA copy={content.actionBanner.copy} />
      </CopyBlock>
      <FAQs data={content.faqs} />
      <LatestNews />
    </Layout>
  )
}

ITSecurityPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default ITSecurityPage

export const query = graphql`
  query ITSecurityPageQuery {
    itsecurityJson {
      metaTitle
      metaDescription
      heroBanner {
        heading
        subheading
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
      intro {
        heading
        copy
      }
      services {
        title
        copy
        image {
          publicURL
        }
        link
        cta
      }
      timeline {
        heading
        steps {
          order
          heading
          copy
        }
        link
        cta
      }
      whyUsSection {
        heading
        image {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 720
              aspectRatio: 1.6666666667
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
        imageAlt
        copy
      }
      features {
        heading
      }
      actionBanner {
        copy
      }
      faqs {
        question
        answer
      }
    }
    homeJson {
      features {
        title
        copy
        link
        image {
          publicURL
        }
        highlight
      }
    }
  }
`
