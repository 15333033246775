import React from 'react'
import PropTypes from 'prop-types'
import { Container, Img, Copy, StyledLink } from './card.css'

export const Card = ({ title, copy, image }) => (
  <Container>
    <Img src={image.publicURL} alt={title} />
    <Copy>
      <h2>{title}</h2>
      <p>{copy}</p>
    </Copy>
  </Container>
)

export const CardWithCTA = ({ title, copy, image, link, cta }) => (
  <StyledLink to={link}>
    <Container>
      <Img src={image.publicURL} alt={title} />
      <Copy>
        <h2>{title}</h2>
        <p>{copy}</p>
        <span>{cta}</span>
      </Copy>
    </Container>
  </StyledLink>
)

Card.propTypes = {
  title: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired
}

CardWithCTA.propTypes = {
  title: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired
}
