import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { Card, CardWithCTA } from './card'
import { Section } from './servicesGrid.css'

const ServicesGrid = ({ services, withCTA }) => (
  <Section>
    {services.map((service, i) => (
      <Fade bottom key={i} delay={i * 100} duration={1000} distance="30px">
        {withCTA ? <CardWithCTA {...service} /> : <Card {...service} />}
      </Fade>
    ))}
  </Section>
)

ServicesGrid.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default ServicesGrid
