import styled from 'styled-components'
import media from 'constants/breakpoints'

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 1128px;
  margin: 0 auto var(--lg);
  padding: 0 var(--md);

  ${media.tablet`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
  `}

  > div {
    margin-bottom: var(--md);

    ${media.tablet`
      flex: 0 1 calc(50% - 12px);
    `}
    ${media.desktop`
      flex: 0 1 calc(33.33333333333% - 16px);
    `}
  }

`
