import styled from 'styled-components'
import media from 'constants/breakpoints'

export const Heading = styled.h3`
  text-align: center;
  font-size: var(--titleLevel1);
  margin-bottom: var(--lg);
`
export const Container = styled.div`
  display: grid;
  grid-gap: var(--lg);
  grid-template-columns: repeat(1, minmax(0, 1fr));
  ${media.tablet`
    grid-template-columns: repeat(3, minmax(0,1fr));
    grid-gap: var(--md);
  `}
`
export const Card = styled.div``
export const Link = styled.a`
  height: 100%;
  :hover {
    text-decoration: none;
  }
`
export const Title = styled.h2`
  font-size: var(--copyMedium);
  margin-bottom: var(--sm);
`
export const Meta = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: var(--sm) 0;
  span {
    font-size: var(--copyNormal);
    color: var(--lightGrey);
  }
`
export const Continue = styled.div`
  color: var(--tertiary);
  text-decoration: underline;
  font-size: var(--copyNormal);
  font-family: var(--headings);
  font-weight: 400;
  font-style: normal;
  line-height: 1.6;
  margin-top: var(--sm);
  :hover {
    // text-decoration: none;
  }
`
