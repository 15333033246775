import React from 'react'
// import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Heading, Container, Link, Card, Title, Meta, Continue } from './latestNews.css'
import CopyBlock from 'components/copyBlock'

const LatestNews = () => {
  const data = useStaticQuery(graphql`
    query LatestNewsQuery {
      allFeedLatestNewsRss(limit: 3) {
        nodes {
          link
          title
          isoDate(formatString: "D MMM Y")
          contentSnippet
          categories
          localImage {
            childImageSharp {
              gatsbyImageData(width: 768, aspectRatio: 1.5, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  `)

  return (
    <CopyBlock bgTint>
      <Heading>Our Latest News</Heading>
      <Container>
        {data.allFeedLatestNewsRss.nodes.map((post, i) => (
          <Card key={i}>
            <Link href={post.link} rel="noopener noreferrer">
              <article>
                <header>
                  <GatsbyImage
                    image={post.localImage ? post.localImage.childImageSharp.gatsbyImageData : {}}
                    alt={post.title}
                  />
                  <Meta>
                    <span>{post.categories[0]}</span>
                    <span>{post.isoDate}</span>
                  </Meta>
                  <Title>{post.title}</Title>
                </header>
                <p>
                  {post.contentSnippet.substring(0, 150)}
                  {post.contentSnippet.length > 150 ? '...' : ''}
                </p>
                <Continue>Continue Reading</Continue>
              </article>
            </Link>
          </Card>
        ))}
      </Container>
    </CopyBlock>
  )
}

// LatestNews.propTypes = {
//   data: PropTypes.object.isRequired
// }

export default LatestNews
